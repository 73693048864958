import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import merge from 'lodash/merge';

import global from './global/en.json';
import flattenMessages from '../helpers/flattenMessages';

import mobile from './auth/mobile';
import web from './auth/web';
import error from './404';

const DEFAULT_LOCALE = 'en';
export const appLocales: {
  [locale: string]: { path: string; locale: string; default?: boolean };
} = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
  },
  /*
  zh: {
    path: 'zh',
    locale: '中文',
  },
  ru: {
    path: 'ru',
    locale: 'Русский',
  },
  es: {
    path: 'es',
    locale: 'Español',
  },
    ja: {
      path: 'ja',
      locale: '日本語',
    },
  ko: {
    path: 'ko',
    locale: '한국어',
  },

  fr: {
    path: 'fr',
    locale: 'Français',
  },
  */
};

const messagesMap: { [locale: string]: Record<string, unknown> } = {
  mobile,
  web,
  '404': error,
};

interface Props extends PropsWithChildren {
  localeDir: string;
  locale: string;
  netlify?: Record<string, unknown>;
}

// THIS WHOLE FUNCTION SHOULD BE RE-WRITTEN
// LOCALIZATION IN THIS PROJECT DOESN'T HAPPEN AT PAGE LEVEL
// SO MOST OF THIS IS REDUNDENT
const Intl: FunctionComponent<Props> = props => {
  const { localeDir, children, locale, netlify } = props;

  const splitLocaleDir = localeDir.split('/').reduce((prev: string[], curr) => {
    if (curr === '') return prev;
    return [...prev, curr];
  }, []);

  // console.log(splitLocaleDir);
  let pageName = '404';

  Object.keys(messagesMap).forEach(value => {
    if (splitLocaleDir.includes(value)) pageName = value;
  });

  pageName = splitLocaleDir[splitLocaleDir.length - 1];

  if (splitLocaleDir.length === 0) pageName = 'home';
  if (
    splitLocaleDir.length === 1 &&
    Object.keys(appLocales).includes(splitLocaleDir[0])
  )
    pageName = 'home';
  /* locale === DEFAULT_LOCALE && splitLocaleDir.length === 0
      ? 'home'
      : splitLocaleDir[0]; */

  // console.log(pageName);

  const locales = messagesMap[pageName] || messagesMap['404'];
  // console.log(locales[locale]);
  const messages = netlify
    ? merge({}, locales[DEFAULT_LOCALE], {
        ...flattenMessages(global, 'global'),
        ...flattenMessages(netlify, pageName),
      })
    : merge({}, locales[DEFAULT_LOCALE], locales[locale]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

export default Intl;
