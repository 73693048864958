import { MessageDescriptor } from 'react-intl';

const flattenMessages = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nestedMessages: Record<string, any>,
  prefix = '',
): MessageDescriptor =>
  Object.keys(nestedMessages).reduce((msgs: Record<string, unknown>, key) => {
    const messages = msgs;
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (
      key === 'schema' ||
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      Array.isArray(value)
    ) {
      messages[prefixedKey] = value;
    } else if (typeof value === 'object' && value !== null) {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

export default flattenMessages;
