import flattenMessages from '../../helpers/flattenMessages';
import en from './en.json';
import es from './es.json';
import ru from './ru.json';
import zh from './zh.json';

export default {
  en: flattenMessages(en, '404'),
  es: flattenMessages(es, '404'),
  ru: flattenMessages(ru, '404'),
  zh: flattenMessages(zh, '404'),
};
