import { PageRendererProps } from 'gatsby';
import React, { FunctionComponent, ReactNode } from 'react';

import Layout from './src/components/Layout';

interface Props {
  element: ReactNode;
  props: PageRendererProps;
}

const wrapPageElement: FunctionComponent<Props> = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export default wrapPageElement;
