import { ThemeProvider } from 'styled-components';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useLocation } from '@reach/router';
import themes, {
  GlobalStyle,
} from '@bitcoin-portal/neko-web-components/dist/themes';
import OsanoClose from '@bitcoin-portal/neko-web-components/dist/OsanoClose';

import Intl from '../../i18n';

type Props = {
  netlify?: Record<string, unknown>;
  localeDir?: string;
  locale?: string;
} & PropsWithChildren;

const Layout: FunctionComponent<Props> = props => {
  const { children, netlify, localeDir, locale } = props;
  const location = useLocation();
  const { pathname } = location || { pathname: localeDir };

  return (
    <Intl
      locale={locale || 'en'}
      localeDir={netlify && localeDir ? localeDir : pathname}
      netlify={netlify}
    >
      <ThemeProvider theme={themes.light}>
        <>
          <GlobalStyle />
          <OsanoClose />

          {children}
        </>
      </ThemeProvider>
    </Intl>
  );
};

export default Layout;
