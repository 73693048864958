import flattenMessages from '../../../helpers/flattenMessages';
import en from './en.json';
import es from './es.json';
import ru from './ru.json';
import zh from './zh.json';

export default {
  en: flattenMessages(en, 'web'),
  es: flattenMessages(es, 'web'),
  ru: flattenMessages(ru, 'web'),
  zh: flattenMessages(zh, 'web'),
};
